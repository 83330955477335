import moment from 'moment'
import 'moment-timezone'
import 'moment/locale/ru'
import axios from 'axios'
import qs from 'qs'
import { i18n } from '@/i18n'

import '@/helpers'
import Sound from '@/helpers/sound'

import OverdueOrder from './OverdueOrder'

import { Order } from '@/Models/Order'
import settings from '@/config/settings'

import TaxiClaims from '@/store/orders/TaxiClaims'
import {
  getOrder,
  list as getListQuery,
  filteredList as getFilteredListQuery
} from '@/functions/axios/v2/order_requests'

const version = localStorage.getItem('version') ?? settings.USER_VERSION

function cleanup (rawOrders) {
  const clenedOrders = []
  const timeLimit = moment().subtract(1, 'day').hours(3).minutes(0).seconds(0)

  for (const order of rawOrders ?? []) {
    const orderModel = new Order(order)

    if (orderModel.moment >= timeLimit || !orderModel.completed) {
      clenedOrders.push(order)
    }
  }

  return clenedOrders
}

function parseOrders (historyOrders) {
  let orders = []

  historyOrders = cleanup(historyOrders)

  // Если тип данных нужной версии создадим из них классы, иначе пустой массив
  if (localStorage.getItem('orders_schema') === '1.0.1') {
    for (const order of historyOrders) {
      orders.push(new Order(order))
    }
  }
  orders = orders.sort(function (a, b) {
    return new Date(b.datetime) - new Date(a.datetime)
  })

  return orders
}

export default {
  modules: {
    overdue_order: OverdueOrder,
    taxi_claims: TaxiClaims
  },
  state: {
    orders: localStorage.getItem('orders') ? parseOrders(JSON.safeParse(localStorage.getItem('orders'))) : [],
    order_statuses: localStorage.getItem('order_statuses') ? JSON.safeParse(localStorage.getItem('order_statuses')) : [],
    orders_appointed: localStorage.getItem('orders_appointed') ? JSON.safeParse(localStorage.getItem('orders_appointed')) : [],
    order_lastupdate: null,
    isOrdersLoading: false,
    filters: {
      type: 'all',
      date: null,
      statuses: []
    },
    pagination: {
      page: 1,
      total: 0,
      per_page: 50
    },
    orderDialog: {
      show: false,
      order: {},
      changeModal: {
        show: false,
        change: null
      }
    }
  },
  mutations: {
    updateOrder (state, order) {
      state.orders.map(item => {
        if (item.id === order.id) {
          return order
        }
        return item
      })
    },
    changeOrderLoading (state, val = null) {
      state.isOrdersLoading = val ?? !state.isOrdersLoading
    },
    updateFilters (state, val = {}) {
      state.filters = val
    },
    updatePagination (state, val = {}) {
      state.pagination = val
    },
    updatePaginationPage (state, val = 1) {
      state.pagination.page = val
    }
  },

  getters: {
    getOrders: (state) => () => {
      return state.orders
    },

    getActiveOrders (state) {
      return state.orders.reduce((out, order) => {
        if (!order.completed) {
          out.push(order)
        }
        return out
      }, [])
    },

    getNewOrders (state) {
      const orders = []

      for (const order of state.orders) {
        if (!order.accepted && !order.completed) {
          orders.push(order)
        }
      }

      return orders
    },

    getClosedOrders (state) {
      const orders = []

      for (const order of state.orders) {
        if (settings.ORDER_STATUSES_CLOSED.includes(parseInt(order.status))) {
          orders.push(order)
        }
      }

      return orders
    },

    getClosedOrdersDay: (state) => (filterDate = null) => {
      const orders = []
      const daysLimit = 1
      const currentDayStep = 0

      for (const order of state.orders) {
        if (!currentDayStep >= daysLimit || filterDate) {
          if (settings.ORDER_STATUSES_CLOSED.includes(order.status)) {
            const orderDate = moment(order.datetime).format('DD.MM.YYYY')

            if (filterDate) {
              if (orderDate !== filterDate) {
                continue
              }
            }
            orders.push(order)
          }
        }
      }

      return orders
    },

    getPreOrdersDay: (state) => (filterDate = null) => {
      const orders = []
      const daysLimit = 1
      const currentDayStep = 0

      for (const order of state.orders) {
        if (!currentDayStep >= daysLimit || filterDate) {
          const orderDate = moment(order.datetime).format('DD.MM.YYYY')

          if (filterDate) {
            if (orderDate !== filterDate) {
              continue
            }
          }
          orders.push(order)
        }
      }

      return orders
    },

    getOrderStatusName: (state) => (id) => {
      const result = state.order_statuses.find(x => parseInt(x.id) === parseInt(id))
      return result ? result.name : ''
    },

    statusNames (state) {
      return state.order_statuses.reduce((out, status) => {
        out[status.id] = status.name
        return out
      }, {})
    },

    isOrdersLoading (state) {
      return state.isOrdersLoading
    },

    statusesForFilters: (state) => {
      return [
        { label: i18n.t('order_filter_statuses.waiting'), value: 'waiting', includes: [settings.ORDER_STATUS_WAITING, settings.ORDER_STATUS_NEW] },
        { label: i18n.t('order_filter_statuses.new'), value: 'new', includes: [settings.ORDER_STATUS_TIME_TO_COOK] },
        { label: i18n.t('order_filter_statuses.cooking'), value: 'cooking', includes: [settings.ORDER_STATUS_COOKING] },
        { label: i18n.t('order_filter_statuses.ready'), value: 'ready', includes: [settings.ORDER_STATUS_READY, settings.ORDER_STATUS_WAITING_DELIVERY] },
        { label: i18n.t('order_filter_statuses.delivering'), value: 'delivering', includes: [settings.ORDER_STATUS_DELIVERING] }
        // { label: i18n.t('order_filter_statuses.closed'), value: 'closed', includes: [settings.ORDER_STATUS_CLOSED] },
        // { label: i18n.t('order_filter_statuses.canceled'), value: 'canceled', includes: [settings.ORDER_STATUS_CANCELED] }
      ]
    },

    statusesName: (state) => {
      return state.order_statuses.reduce((out, status) => {
        out[status.id] = status.name
        return out
      }, [])
    },

    order (state) {
      return state.orderDialog.order
    }
  },

  actions: {
    getOrder,
    getListQuery,
    getFilteredListQuery,
    refresh ({ dispatch }) {
      dispatch('loadOrders')
    },

    loadFilteredOrders ({ dispatch, commit, state, getters }, options = {}) {
      if (!options.filters) {
        console.log('no filters')
        return false
      }

      return new Promise((resolve, reject) => {
        dispatch('getFilteredListQuery', {
          page: options.page,
          filters: options.filters
        }).then(response => {
          const orders = response.data.orders.map(order => new Order(order))

          resolve({ orders, pagination: response.data.pagination })
        }).catch(error => {
          reject(error)
        })
      })
    },

    /**
     * Загрузить заказы по указанной дате
     * @param commit
     * @param state
     * @param getters
     * @param dispatch
     * @param options
     * @returns {*}
     */
    loadOrdersWithDate ({ commit, state, getters, dispatch }, options = {}) {
      if (!options.date) {
        dispatch('showSnackbar', i18n.t('required_field', { field: i18n.t('date') }))
        return false
      }

      if (!this.state.user.id) {
        dispatch('showSnackbar', i18n.t('required_field', { field: i18n.t('user') }))
        return false
      }

      return new Promise((resolve, reject) => {
        dispatch('getListQuery', {
          load_date: options.date,
          pre: options.pre,
          page: options.page
        }).then(response => {
          if (options.pre) {
            state.bar.badges.pre = response.data?.badges?.pre ?? 0
          } else {
            state.bar.badges.closed = response.data?.badges?.closed ?? 0
          }

          const orders = response.data.orders.map(order => new Order(order))

          resolve({ orders, pagination: response.data.pagination })
        }).catch(error => {
          reject(error)
        })
      })
    },
    checkApiConnector ({ commit, state }, callback = false) {
      if (!state.user.id) return false
      let test = false
      axios({
        method: 'get',
        url: 'http://localhost:7127/check'
      })
        .then((response) => {
          test = true
        })
        .catch(() => {
          test = false
        })
      if (test !== this.state.user.isApiConnectorSushiMarket) {
        axios({
          method: 'post',
          url: settings.API_HOST + '/kassa/isApiConnector',
          data: qs.stringify({
            user_id: state.user.id,
            token: state.user.token,
            check: test
          })
        })
        state.user.isApiConnectorSushiMarket = test
        localStorage.setItem('user', JSON.stringify(state.user))
      }
    },
    loadOrders ({ dispatch, commit, state }) {
      const forceClearActive = true // clear all active orders

      if (!state.user.id) {
        console.error('no user id')
        return
      }

      if (state.isOrdersLoading) {
        // TODO: может потребоваться callback-стек
        return
      }

      if (state.order_lastupdate >= moment().subtract(1, 'seconds')) {
        commit('changeOrderLoading', false)
        return
      }

      state.order_lastupdate = moment()

      commit('changeOrderLoading', true)

      dispatch('getListQuery', {
        page: state.pagination.page
      }).then(response => {
        if (response.data.version && response.data.version !== version) {
          location.reload(true)
          localStorage.setItem('version', response.data.version)
        }

        const responseOrders = cleanup(response.data.orders)

        if (forceClearActive) {
          if (state.orders) {
            state.orders = state.orders.filter(order => order.completed)
          }
        }

        for (const order of responseOrders) {
          let existsIndex = -1
          let existsAppointedIndex = -1
          // eslint-disable-next-line no-unused-vars
          let exists = false
          let existsAppointed = false

          if (state.orders) {
            existsIndex = state.orders.findIndex(item => parseInt(item.id) === parseInt(order.id))
            exists = state.orders[existsIndex]
          }
          if (state.orders_appointed) {
            existsAppointedIndex = state.orders_appointed.findIndex(item => parseInt(item.id) === parseInt(order.id))
            existsAppointed = state.orders_appointed[existsAppointedIndex]
          }

          if (existsAppointedIndex !== -1) {
            if (parseInt(existsAppointed.revision) !== parseInt(order.revision) && !parseInt(order.closed)) {
              Sound.changeSound.play() // Заказ изменен, играем звук
              dispatch('showSnackbar', `${i18n.t('order')} № ${order.id}, ${i18n.t('change')}`)
            }
            state.orders_appointed[existsAppointedIndex] = {
              id: parseInt(order.id),
              revision: parseInt(order.revision)
            }
          } else {
            if (!parseInt(order.closed)) {
              Sound.notificationSound.play() // Новый заказ, играем звук
              dispatch('showSnackbar', `${i18n.t('new_order')} № ${order.id}, ${i18n.t('text.confirm_order')}!`)
            }
            state.orders_appointed.push({
              id: parseInt(order.id),
              revision: parseInt(order.revision)
            })
          }

          if (existsIndex !== -1) {
            state.orders[existsIndex] = new Order(order)
          } else {
            state.orders.push(new Order(order))
          }
        }

        commit('updatePagination', {
          ...state.pagination,
          ...response.data.pagination
        })

        state.orders.sort((a, b) => new Date(b.datetime) - new Date(a.datetime))
        state.orders.sort((a, b) => a.status - b.status)
        state.orders.sort((a, b) => (a.accepted - b.accepted))

        localStorage.setItem('orders', JSON.stringify(responseOrders))
        localStorage.setItem('orders_appointed', JSON.stringify(state.orders_appointed))
        localStorage.setItem('orders_schema', '1.0.1')

        state.bar.badges.active = (response.data.badges.active !== null && response.data.badges.active !== undefined) ? response.data.badges.active : 0
        state.bar.badges.pre = (response.data.badges.pre !== null && response.data.badges.pre !== undefined) ? response.data.badges.pre : 0
      }).catch(error => {
        if (error.response === undefined) {
          state.errors.push({
            message: i18n.t('errors.server_error')
          })
          dispatch('showSnackbar', i18n.t('errors.server_error'))
        }
      }).finally(() => {
        commit('changeOrderLoading', false)
      })
    },

    loadOrderStatuses ({ dispatch, state, commit }, refresh = false) {
      if (!refresh && localStorage.getItem('order_statuses')) {
        state.order_statuses = JSON.safeParse(localStorage.getItem('order_statuses'))
        return
      }

      axios.get(settings.API_HOST + '/kassa/status_list').then(response => {
        state.order_statuses = response.data
        localStorage.setItem('order_statuses', JSON.stringify(state.order_statuses))
      }).catch(error => {
        if (error.response.status === 400) {
          state.errors.push({
            message: error.response.data
          })
          dispatch('showSnackbar', error.response.data.message)
        }
      })
    },

    loadOrderHistory ({ dispatch, state }, order_id = null, callback = null) {
      // eslint-disable-next-line camelcase
      if (!order_id) {
        console.warning(i18n.t('form.valid_msg.enter_order_id'))
        return false
      }

      return axios({
        method: 'post',
        url: settings.API_HOST + '/kassa/order/history',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: qs.stringify({
          user_id: state.user.id,
          token: state.user.token,
          order_id: order_id
        })
      }).then(response => {
        if (callback) {
          callback(state.orders)
        }

        return response.data
      }).catch(error => {
        if (error.response.status === 400) {
          state.errors.push({
            message: error.response.data
          })
          dispatch('showSnackbar', error.response.data.message)
        }
      })
    },
    loadOrderProducts ({ dispatch, state }, orderId = null, callback = null) {
      if (!orderId) {
        console.warning(i18n.t('form.valid_msg.enter_order_id'))
        return false
      }

      return axios({
        method: 'POST',
        url: settings.API_HOST + '/kassa/order/products/' + orderId,
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: qs.stringify({
          user_id: state.user.id,
          token: state.user.token
        })
      }).then(response => {
        if (callback) {
          callback(state.orders)
        }
        return response.data
      }).catch(error => {
        if (error.response.status === 400) {
          state.errors.push({
            message: error.response.data
          })
          dispatch('showSnackbar', error.response.data.message)
        }
      })
    },

    showOrder ({ state, dispatch, commit }, order) {
      commit('setLoading', { value: true })

      dispatch('getOrder', order).then(response => {
        state.orderDialog.show = true
        state.orderDialog.order = new Order(response.data)
      }).finally(() => {
        commit('setLoading', { value: false })
      })
    },
    updateOrder ({ state, dispatch, getters }) {
      dispatch('getOrder', getters.order.id).then(response => {
        state.orderDialog.order = new Order(response.data)
      })
    },
    getConfirmationCode ({ state, dispatch }, { orderId }) {
      return axios.get(settings.API_HOST + '/kassa/order/get_confirmation_code', {
        params: {
          order_id: orderId
        }
      })
    }
  }
}
